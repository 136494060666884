// tailwindcss css
@tailwind base;
@tailwind components;
@tailwind utilities;

// local css
@import 'components/kit/styles.scss';

#import-products .guZdik {
    min-width: 512px !important;
    height: 156px !important;
}

.antd-table-border .ant-table {
    border: 2px solid red;
}

.antd-table-border .ant-table-thead>tr>th,
.antd-table-border .ant-table-tbody>tr>td {
    border: 1px solid red;
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
}

.ant-table-tbody>tr>td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.ant-btn-round {
    height: 24px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.ant-btn-primary {
    color: #1890ff !important;
    background: transparent !important;
}

.ant-btn-danger {
    color: #ff4d4f !important;
    background: transparent !important;
}

.anticon {
    vertical-align: middle !important;
}

.ant-btn>span {
    vertical-align: middle !important;
}

.ant-drawer-body>fieldset>div>b,
.ant-drawer-body>fieldset>div>span {
    font-size: 0.75rem;
}

.ant-drawer-body fieldset {
    border: 1px solid #000;
    margin-bottom: 15px;
}

.ant-drawer-body legend {
    float: none;
    display: inline-block;
    width: auto;
    font-size: 1rem;
    padding: 0 10px;
    margin-bottom: 0;
}

.header-logo img {
    height: 3rem;
}

.telecaller-reports .ant-table-measure-row {
    display: none;
}

/* Add border and other custom styles to table header cells */
.telecaller-reports .ant-table-thead>tr:nth-child(1)>th.ant-table-cell:nth-child(1),
.telecaller-reports .ant-table-thead>tr:nth-child(2)>th.ant-table-cell:nth-child(1) {
    border: none !important;
}

.telecaller-reports .ant-table-thead>tr:nth-child(1)>th:not(:nth-child(2)) {
    border-left: 0px solid !important;
}

.telecaller-reports .ant-table-thead>tr:nth-child(1)>th,
.telecaller-reports .ant-table-thead>tr:nth-child(2)>th {
    border: 1px solid lightgray !important;
    /* Bottom border */
    padding: 8px 0;
}

.telecaller-reports .ant-table-thead>tr:nth-child(2)>th {
    border-left: 0px !important;
}

.telecaller-reports .ant-table-thead>tr:nth-child(2)>th:nth-child(2) {
    border-left: 1px solid lightgray !important;
}

.telecaller-reports .ant-table-thead>tr:nth-child(1)>th {
    border-bottom: 0px solid !important;
}

.telecaller-reports .ant-table-tbody>tr>td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.telecaller-reports .ant-table-tbody>tr>td {
    padding: 0.5px !important;
    // border: 1px solid !important;
}


.disposition-filter::-webkit-scrollbar {
    width: 3px;
    scrollbar-width: thin;
    height: 3px;
}

.disposition-filter::-webkit-scrollbar-track {
    // background-color: darkgrey;
    scrollbar-color: #3ebec8;
}

.disposition-filter::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #3ebec8;
}

#subDisposition .ant-table-thead {
    display: none !important;
}

#subDisposition {
    margin: 0 !important;
}

#disposition .ant-table-cell {
    padding: 5px 5px !important;
}

#subDisposition #footer>td,
#disposition #footer>td {
    border-top: 1px solid #f0f0f0;
    background-color: grey !important;
}

#subDisposition #footer td span,
#disposition #footer td span {
    color: white !important;
}

#disposition .expanded-row {
    background-color: #fbfbfb !important;
}

#disposition .ant-table-expanded-row-level-1 {
    display: none;
}

.lead-details-spin .ant-spin-dot-item {
    background-color: #262626;
}

.import-lead-summary-table .ant-table-cell{
    text-align: center !important;
}