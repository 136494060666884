.ameyo-modal {
    transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
}

.ant-modal {
    padding-bottom: 0px !important;
    position: fixed;
    top: 0px;
}

.minimized {
    height: 60px !important;
    top: calc(95vh - 60px) !important;
}

.ant-layout-has-sider aside .ant-layout-sider-trigger {
    position: absolute !important;
}

.ant-layout-footer {
    background: white !important;
    border-top: 1px solid #e3e1e1 !important;
    padding: 5px 50px !important;
    position: sticky;
    bottom: 0;
    z-index: 2;
}

.ameyoButton:hover {
    background-color: aliceblue !important;
}

#customerDetails div {
    color: white;
    font-size: 0.75rem;
}

#scheduleDetails div,
#driverDetails div {
    color: white;
    font-size: 0.75rem;
}

.tabErrorNotification {
    padding: 10px;
    border-color: #c31c1c;
    color: #c31c1c;
}

.tabErrorNotification div {
    font-size: 14px;
    padding: 12px;
}